<script>
import Layout from "../../../layouts/main";
import Master from "@/apis/Master";
import {numeric, required} from "vuelidate/lib/validators";
import Vue from "vue";
import moment from "moment";
import Swal from "sweetalert2";
import PageHeader from "@/components/page-header";

/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "ORDER DETAILS",
      items: [
        {
          text: "DASHBOARD",
          href: "/"
        },
        {
          text: "CANCELLED ORDERS",
          href: "/cancel-order/cancel-order-list"
        },
        {
          text: "ORDER DETAILS",
          active: true
        }
      ],
      ordersData: [],
      deliveryData: [],
      checkbox: [],
      isCheckAll:false,
      paymentData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      paymentModeData: [],
      showmodal: false,
      showmodalPayment: false,
      submitted: false,
      submittedPayment: false,
      showRejectModel: false,
      submittedReject: false,
      sortBy: "orderid",
      delivery:{
        referenceId: "",
        dateAndTime: "",
        collectedByName: "",
        collectedByMobile: "",
        file: ""
      },
      payment:{
        referenceId: "",
        amount: "",
        paymentDateAndTime: "",
        paymentMode: "",
        file:""
      },
      reject:{
        paymentId : "",
        rejectedReason: ""
      },
      sortDesc: false,
      paid: 0,
      status: [],
      fields: [
        { key: "images", sortable: false, label: "PRODUCT" },
        { key: "orderid", sortable: false, label: "ORDER DETAILS" },
        { key: "orderBy", sortable: false, label: "ORDERED BY" },
        { key: "delivery", sortable: false, label: "DELIVERY" },
        { key: "price", sortable: false, label: "PRICE" },
      ]
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.ordersData.length;
    }
  },
  created() {
    //
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.ordersData.length;


    Master.orderDetails({
      orderId: localStorage.getItem('orderId')
    }).then((res) => {
      console.log(res)
      this.ordersData = res.data.data;
    })
    Master.orderDeliveryList({
      orderId: localStorage.getItem('orderId')
    }).then((res) => {
      console.log(res)
      this.deliveryData = res.data.data;
    })
    Master.orderPaymentsList({
      orderId: localStorage.getItem('orderId')
    }).then((res) => {
      console.log(res)
      this.paymentData = res.data.data;
    })
    Master.paymentMode({
      trash: 0
    }).then((res) => {
      console.log(res)
      this.paymentModeData = res.data.data;
    })


  },
  validations: {
    delivery: {
      referenceId: { required },
      dateAndTime: { required },
      collectedByName: { required },
      collectedByMobile: { required,numeric },
      file: { required }
    },
    payment: {
      referenceId: { required },
      paymentDateAndTime: { required },
      amount: { required },
      paymentMode: { required },
      file: { required }
    },
    reject:{
      rejectedReason: {required}
    }
  },
  methods: {
    /**
     * Search the table data with search input
     */


    deliveryRecipt(event){
      this.delivery.file = event.target.files[0]
    },
    paymentReceipt(){
      this.payment.file = event.target.files[0]
    },
    rejectModal(data){
      this.showRejectModel = true;
      this.reject.paymentId = data
    },
    productRejectModal(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else{
        this.showRejectModel = true;
      }

    },

   async handleSubmit(){
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.delivery.$invalid) {
        return;
      } else {
        var orderId = localStorage.getItem('orderId')
        var userId = JSON.parse(localStorage.getItem('user'))
        const fd = new FormData();
        const config = { headers: { "Content-Type": "multipart/form-data" } };
        fd.append("image",this.delivery.file);
        fd.append('data',JSON.stringify({
              "orderId" : orderId,
              "deliveredBy" : userId.id,
              "deliveryRefId" : this.delivery.referenceId,
              "deliveredOn" : moment(this.delivery.dateAndTime).format("DD-MM-YYYY HH:ss:mm"),
              "collectedPersonName" : this.delivery.collectedByName,
              "collectedPersonMobile" : this.delivery.collectedByMobile
            }
        ));
        Master.addOrderDelivery(fd,config).then((res) => {
          console.log(res)
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          // this.successMessage = res.data.message;

          Master.orderDeliveryList({
            orderId: orderId
          }).then((res) => {
            console.log(res)
            this.deliveryData = res.data.data;
          })

          this.showmodal = false;
        }).catch((err) => {
          console.log(err)
          // this.errorMessage = err.response.data.error.collectedPersonName[0];
          this.$bvToast.toast(""+err.response.data.error.collectedPersonName[0]+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });
          // this.$bvToast.toast(""+err.response.data.errors+"", {
          //   title: `Error Message`,
          //   variant: 'danger',
          //   solid: true
          // });
          this.showmodal = false;
        })

      }
      this.submitted = false;
      this.delivery.referenceId = "";
      this.delivery.dateAndTime = "";
      this.delivery.collectedByName = "";
      this.delivery.collectedByMobile = "";
      this.delivery.file = "";
    },
    handlePaymentSubmit(){
      this.submittedPayment = true;
      this.$v.$touch();
      if (this.$v.payment.$invalid) {
        return;
      } else {
        var orderId = localStorage.getItem('orderId')
        var userId = JSON.parse(localStorage.getItem('user'))
        const fd = new FormData();
        const config = { headers: { "Content-Type": "multipart/form-data" } };
        fd.append("image",this.payment.file);
        fd.append('data',JSON.stringify({
              "orderId" : orderId,
              "paymentMode" : 1,
              "paidAmount" : this.payment.amount,
              "confirmedBy" : userId.id,
              "paidOn" : moment(this.payment.paymentDateAndTime).format("DD-MM-YYYY HH:ss:mm"),
              "paymentRefId" : this.payment.referenceId,
              "paymentStatus" : 1
            }
        ));
        Master.addOrderPayment(fd,config).then((res) => {
          console.log(res)
          Vue.swal({
            position: "center",
            icon: "success",
            title: ""+res.data.message+"",
            showConfirmButton: false,
            timer: 1500
          });
          // this.successMessage = res.data.message;

          Master.orderPaymentsList({
            orderId: orderId
          }).then((res) => {
            console.log(res)
            this.paymentData = res.data.data;
          })

          this.showmodalPayment = false;
        }).catch((err) => {
          console.log(err)
          this.$bvToast.toast(""+err.response.data.paidAmount+"", {
            title: `Error Message`,
            variant: 'danger',
            solid: true
          });
          this.showmodalPayment = false;
          this.payment.paymentMode = ""
          this.payment.paymentDateAndTime = ""
          this.payment.referenceId = ""
          this.payment.file = ""
          this.payment.amount = ""
        })
      }
    },
    handleRejectedSubmit(){
      if (this.checkbox == null){
        alert("payment")
        this.submittedReject = true;
        this.$v.$touch();
        if (this.$v.reject.$invalid) {
          return;
        } else {
          this.paymentReject()
        }
      }else{
        alert("Cart")
        this.submittedReject = true;
        this.$v.reject.rejectedReason.$touch();
        if (this.$v.reject.rejectedReason.$invalid) {
          return;
        } else {
          if (confirm('Are you sure you want to cancel this item?')) {
            var userId = JSON.parse(localStorage.getItem('user'))
            Master.cancelProductOrder({
              cartIds: this.checkbox,
              userId: userId.id,
              orderId: localStorage.getItem('orderId'),
              cancelReason: this.reject.rejectedReason
            }).then((res) => {
              // this.successMessage = res.data.message;
              Vue.swal({
                position: "center",
                icon: "success",
                title: "<p style='color: red'>"+res.data.message+"</p>",
                showConfirmButton: false,
                timer: 1500
              });
              this.checkbox = []
              this.isCheckAll = false
              this.showRejectModel = false
              this.submittedReject = false
              this.reject.rejectedReason = ""
              Master.orderDetails({
                orderId: localStorage.getItem('orderId')
              }).then((res) => {
                console.log(res)
                this.ordersData = res.data.data;
              })
            })
          }
        }

      }

    },
    deleteDelivery(id){
      var userId = JSON.parse(localStorage.getItem('user'))
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          Master.deliveryDelete(
              {
                userId: userId.id,
                id: id
              }).then(res => {
            console.log(res)
            Swal.fire("Deleted!", "Your data has been deleted.", "success");
            // this.successMessage = res.data.message;
            Master.orderDeliveryList({
              orderId: localStorage.getItem('orderId')
            }).then((res) => {
              console.log(res)
              this.deliveryData = res.data.data;
            })
          })
        }
      });
    },
    deletePayment(id){

      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          // var user = JSON.parse(localStorage.getItem('user'))
          Master.paymentDelete(
              {
                id: id,
                userId: 1
              }).then(res => {
            console.log(res)
            Swal.fire("Deleted!", "Your data has been deleted.", "success");
            // this.successMessage = res.data.message;
            Master.orderPaymentsList({
              orderId: localStorage.getItem('orderId')
            }).then((res) => {
              console.log(res)
              this.paymentData = res.data.data;
            })
          })
        }
      });
    },
    paymentConfirm(id){
      Master.orderPaymentConfirm(
              {
                id: id,
                userId: 1,
                rejectedReason: "",
                paymentStatus:1
              }).then(res => {
        console.log(res)
        Vue.swal({
          position: "center",
          icon: "success",
          title: ""+res.data.message+"",
          showConfirmButton: false,
          timer: 1500
        });
        // this.successMessage = res.data.message;
        Master.orderPaymentsList({
          orderId: localStorage.getItem('orderId')
        }).then((res) => {
          console.log(res)
          this.paymentData = res.data.data;
        })


      })


    },
    paymentReject(){
      Master.orderPaymentConfirm(
              {
                id: this.reject.paymentId,
                userId: 1,
                rejectedReason: this.reject.rejectedReason,
                paymentStatus:2
              }).then(res => {
        console.log(res)
        Vue.swal({
          position: "center",
          icon: "success",
          title: ""+res.data.message+"",
          showConfirmButton: false,
          timer: 1500
        });
        // this.successMessage = res.data.message;
        Master.orderPaymentsList({
          orderId: localStorage.getItem('orderId')
        }).then((res) => {
          console.log(res)
          this.paymentData = res.data.data;
        })
        this.showRejectModel = false
        this.submittedReject = false
      })


    },


    hideModal() {
      this.submitted = false;
      this.showmodal = false;
      this.delivery.referenceId = "";
      this.delivery.dateAndTime = "";
      this.delivery.collectedByName = "";
      this.delivery.collectedByMobile = "";
      this.delivery.file = "";
    },
    hideRejectModel(){
      this.showRejectModel = false
      this.submittedReject = false
    },
    hideModalPayment() {
      this.submittedPayment = false;
      this.showmodalPayment = false;
      this.payment.paymentMode = ""
      this.payment.paymentDateAndTime = ""
      this.payment.referenceId = ""
      this.payment.file = ""
      this.payment.amount = ""
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout style="text-transform: uppercase !important;">
    <PageHeader :title="title" :items="items" />



    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0 cancel_order_details">
            <div class="row">
              <div class="col-md-6">
                <template>
                  <p class="font-weight-bold ml-2 mt-2" style="color: black"><u>ORDER DETAILS</u></p>
                </template>
              </div>
              <div class="col-md-6">
                <template>
                  <p class="font-weight-bold ml-2 mt-2" style="color: black"><u>CUSTOMER DETAILS</u></p>
                </template>
              </div>
            </div>


<!--            <hr/>-->
              <div class="row">
                <div class="col-md-6">
                  <label class="ml-4 mt-2">ORDER ID :</label>
                  <p class="ml-2 badge font-size-12">{{this.ordersData.orderNum}}</p>
                </div>
                <div class="col-md-6">
                  <label  class="ml-4">NAME :</label>
                  <p class="ml-2 badge font-size-12" style="text-transform: uppercase !important;"> {{this.ordersData.firstName}}  {{this.ordersData.lastName}}</p>

                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <label  class="ml-4 mt-2">QTY :</label>
                  <p class="ml-2 badge font-size-12">{{this.ordersData.qty}}</p>
                </div>
                <div class="col-md-6">
                  <label  class="ml-4">MOBILE NO :</label>
                  <p class="ml-2 badge font-size-12">+{{this.ordersData.mobile}}</p>
                </div>
              </div>

            <div class="row">
                <div class="col-md-6">
                  <label  class="ml-4">DATE :</label>
                  <p class="ml-2 badge font-size-12">{{this.ordersData.orderDate}}</p>
                </div>

              <div class="col-md-6">
                <label  class="ml-4">EMAIL :</label>
                <p class="ml-2 badge font-size-12" style="text-transform: uppercase !important;">{{this.ordersData.email}}</p>
              </div>

              </div>
            <div class="row">
              <div class="col-md-6">
                <label  class="ml-4">TOTAL AMOUNT :</label>
                <p class="ml-2 badge font-size-12">QAR {{this.ordersData.total.toLocaleString()}}</p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label  class="ml-4">ORDER STATUS :</label>
                <p class="ml-2 badge font-size-12 badge font-size-12 badge-soft-success" v-if="this.ordersData.orderStatus == 1">CONFIRMED</p>
                <p class="ml-2 badge font-size-12 badge font-size-12 badge-soft-warning" v-if="this.ordersData.orderStatus == 2">PARTIAL DELIVERED</p>
                <p class="ml-2 badge font-size-12 badge font-size-12 badge-soft-info" v-if="this.ordersData.orderStatus == 3">DELIVERED ALL</p>
                <p class="ml-2 badge font-size-12 badge font-size-12 badge-soft-danger" v-if="this.ordersData.orderStatus == 4">CANCELLED</p>
              </div>
              <div  class="col-md-6">
                <label  class="ml-4">CANCEL REASON :</label>
                <p class="ml-2 badge font-size-12" v-if="this.ordersData.cancelReason">{{this.ordersData.cancelReason}}</p>
                <p class="ml-2 badge font-size-12" v-else>NA</p>
              </div>
            </div>

<!--            <template>-->
<!--              <p class="font-weight-bold ml-2 mt-2"><u>CUSTOMER DETAILS</u></p>-->
<!--            </template>-->

<!--            <div class="row">-->
<!--              <div class="col-md-4">-->
<!--                <label  class="ml-4">NAME :</label>-->
<!--                <p class="ml-2 badge font-size-12" style="text-transform: uppercase !important;"> {{this.ordersData.firstName}}  {{this.ordersData.lastName}}</p>-->
<!--              </div>-->
<!--              <div class="col-md-4">-->
<!--                <label  class="ml-4">MOBILE NO :</label>-->
<!--                <p class="ml-2 badge font-size-12">{{this.ordersData.mobile}}</p>-->
<!--              </div>-->
<!--              <div class="col-md-4">-->
<!--                <label  class="ml-4">EMAIL :</label>-->
<!--                <p class="ml-2 badge font-size-12" style="text-transform: uppercase !important;">{{this.ordersData.email}}</p>-->
<!--              </div>-->
<!--            </div>-->



          </div>
          </div>
          </div>
          </div>


    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body pt-0"  style="overflow-y: auto;">
            <template>
              <p class="font-weight-bold ml-2 mt-2" style="color: black"><u>BILLING ADDRESS</u></p>
              <div class="row ml-1">
                <p class="ml-1  font-size-12" style="text-transform: uppercase !important; color: black"> {{this.ordersData.billingFirstName}} {{this.ordersData.billingLastName}}<br/>{{this.ordersData.billingAddress}}<br/>{{this.ordersData.billingAddressTwo}}<br/>{{this.ordersData.billingCity}}, {{this.ordersData.billingState}}, {{this.ordersData.billingCountryName}}, {{this.ordersData.billingZipCode}}<br/>M: +{{this.ordersData.billingMobile}}</p>

              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body pt-0"  style="overflow-y: auto;">
            <template>
              <p class="font-weight-bold ml-2 mt-2" style="color: black"><u>SHIPPING ADDRESS</u></p>
              <div class="row ml-1">
                <p class="ml-1  font-size-12" style="text-transform: uppercase !important; color: black"> {{this.ordersData.shippingFirstName}} {{this.ordersData.shippingLastName}}<br/>{{this.ordersData.shippingAddress}}<br/>{{this.ordersData.shippingAddressTwo}}<br/>{{this.ordersData.shippingCity}}, {{this.ordersData.shippingState}}, {{this.ordersData.shippingCountryName}}, {{this.ordersData.shippingZipCode}}<br/>M: +{{this.ordersData.shippingMobile}}</p>
              </div>
            </template>

          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0  table-responsive">
            <template>
              <p class="font-weight-bold ml-2 mt-2" style="color: black">PAYMENT DETAILS</p>
            </template>
            <table class="table">
              <thead>
                <th style="color: black">TOTAL</th>
                <th style="color: black">DISCOUNT</th>
                <th style="color: black">PAID AMOUNT</th>
                <th style="color: black">TOTAL BALANCE</th>
              </thead>
              <tbody>

              <tr>
                <td class="font-size-12">QAR {{this.ordersData.total.toLocaleString()}}</td>
                <td class="font-size-12">QAR {{ (this.ordersData.originalAmount - this.ordersData.total).toLocaleString() }}</td>
                <td class="font-size-12">QAR {{ this.ordersData.paidAmount.toLocaleString() }}</td>
                <td class="font-size-12">
                  QAR {{ (this.ordersData.total - this.ordersData.paidAmount).toLocaleString() }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body pt-0">
                <template>
                  <p class="font-weight-bold ml-2 mt-2" style="color: black">LIST OF PRODUCTS</p>
                </template>

            <div class="table table-responsive col-md-12">
              <table class="table">
                <thead>
                <th style="color: black">PRODUCT</th>
                <th style="color: black">PRODUCT DETAILS</th>
                <th style="color: black">QTY</th>
                <th style="color: black">COST PER ITEM</th>
                <th style="color: black">TOTAL PRICE</th>
                <th style="color: black">CANCEL REASON</th>
                <th style="color: black">STATUS</th>

<!--                <th>DELIVERY STATUS</th>-->
<!--                <th>ACTION</th>-->
                </thead>
                <tbody>
                <tr v-for="(product,index) in ordersData.products" :key="index">
                  <td>
                    <img
                      :src="product.imageUrl"
                      alt="product-img"
                      title="product-img"
                      class="b-avatar-lg"
                  />
                  </td>
                  <td class="font-size-11">
                    <span class="prdt-onr-name">{{product.brand}}</span>
                    <p class="cancelled_order_p">{{product.title}}</p>
                    <p class="mt-1">SKU: {{product.sku}}</p>
                  </td>
                  <td class="font-size-11">
                    {{product.qty}}
                  </td>
                  <td class="font-size-11">
                    QAR {{product.unitPrice.toLocaleString()}}
                  </td>
                  <td class="font-size-11">
                    QAR {{product.total.toLocaleString()}}
                  </td>
                  <td >
                    <p class="font-size-11" v-if="product.cancelReason != null">{{product.cancelReason}}</p>
                    <p class="font-size-11" v-else>NA</p>
                  </td>
                  <td class="font-size-11">
                    <p class="ml-2 badge font-size-12 badge font-size-12 badge-soft-danger" v-if="product.cartStatus == 4">CANCELLED</p>
                    <p class="ml-2 badge font-size-12 badge font-size-12 badge-soft-success" v-else>CONFIRMED</p>
                  </td>


<!--                  <td class="font-size-11">-->
<!--                    <div class="badge font-size-12 badge-soft-success">DELIVERY</div>-->
<!--                  </td>-->
<!--                  <td>-->
<!--                    <a-->
<!--                        href="javascript:void(0);"-->
<!--                        class="text-danger"-->
<!--                        v-b-tooltip.hover-->
<!--                        title="CANCEL"-->
<!--                    >-->
<!--                      <i class="mdi mdi-trash-can font-size-18"></i>-->
<!--                    </a>-->
<!--                  </td>-->
                </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body  pt-0">
            <template>

              <p class="font-weight-bold ml-2 mt-2" style="color: black">DELIVERY HISTORY</p>


            </template>
            <div class="table-responsive">
              <table class="table">
                <thead>
                <th style="font-size: 12px; color: black">DELIVERY REF#</th>
                <th style="font-size: 12px; color: black">DELIVERY BY</th>
                <th style="font-size: 12px; color: black">DATE</th>
                <th style="font-size: 12px; color: black">COLLECTED BY</th>
                <th style="font-size: 12px; color: black">ACTION</th>
                </thead>
                <tbody style="text-transform: uppercase !important;">


                <tr v-for="(deliveryHistory,index) in this.deliveryData" :key="index">
                  <td class="font-size-12">{{deliveryHistory.deliveryRefId}}</td>
                  <td class="font-size-12">{{deliveryHistory.firstName}} {{deliveryHistory.lastNmae}}</td>
                  <td class="font-size-12">{{deliveryHistory.deliveredOn}}</td>
                  <td class="font-size-12"><p>{{deliveryHistory.collectedPersonName}},  {{deliveryHistory.collectedPersonMobile}}</p>
                  </td>
                  <td><a
                      target="_blank"
                      :href="deliveryHistory.deliveryReceipt"
                      class="mr-1 text-dark"
                      v-b-tooltip.hover
                      title="VIEW RECEIPT"
                  ><i class="mdi mdi-file-check font-size-14"></i></a>
                    <!--                    <a-->
                    <!--                        href="javascript:void(0);"-->
                    <!--                        class="mr-1 text-primary"-->
                    <!--                        v-b-tooltip.hover-->
                    <!--                        title="EDIT"-->
                    <!--                    >-->
                    <!--                      <i class="mdi mdi-pencil font-size-14"></i>-->
                    <!--                    </a>-->

                  </td>
                </tr>



                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <template>
              <p class="font-weight-bold ml-2 mt-2" style="color: black">PAYMENT HISTORY</p>
            </template>
            <div class="table table-responsive" >
            <table class="table">
              <thead>
              <th style="font-size: 12px; color: black">PAYMENT REF#</th>
              <th style="font-size: 12px; color: black">AMOUNT</th>
              <th style="font-size: 12px; color: black">PAYMENT MODE</th>
              <th style="font-size: 12px; color: black">DATE</th>
              <th style="font-size: 12px; color: black">RECEIVED BY</th>
              <th style="font-size: 12px; color: black">STATUS</th>
              <th style="font-size: 12px; color: black">ACTION</th>
              </thead>
              <tbody style="text-transform: uppercase !important;">
              <tr v-for="(payment,index) in this.paymentData" :key="index">
                <td class="font-size-10">{{payment.paymentRefId}}</td>
                <td class="font-size-10"><p>QAR {{payment.paidAmount.toLocaleString()}}</p></td>
                <td class="font-size-10">{{payment.paymentMode}}</td>
                <td class="font-size-10">{{payment.paidOn}}
                </td>
                <td class="font-size-10">{{payment.firstName}} {{payment.lastNmae}}
                </td>
                <td class="font-size-10">
                  <div class="badge font-size-12 badge-soft-warning" v-if="payment.paymentStatus == 0">PENDING CONFIRMATION</div>
                  <div class="badge font-size-12 badge-soft-success" v-if="payment.paymentStatus == 1">CONFIRMED</div>
                  <div class="badge font-size-12 badge-soft-danger" v-if="payment.paymentStatus == 2" >REJECT</div>
                <td>
                <a
                  target="_blank"
                    :href="payment.paymentReceipt"
                    class="mr-2 text-dark"
                    v-b-tooltip.hover
                    title="VIEW RECEIPT"
                ><i class="mdi mdi-file-check font-size-14"></i></a>


                </td>
              </tr>
              </tbody>
            </table>
            </div>
          </div>

        </div>
        <div align="right">
          <router-link to="/cancel-order/cancel-order-list"><a href="javascript:void(0);" class="btn btn-dark btn-sm mb-4 mr-1">
            <i class="mdi mdi-arrow-left mr-2"></i> BACK
          </a></router-link>
        </div>

      </div>
     </div>
    <!-- Modal Add -->
    <b-modal
        id="modal-1"
        v-model="showmodal"
        title="ADD DELIVERY DETAILS"
        title-class="text-dark font-18"
        hide-footer
        @close="hideModal"
    >

      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="refId">DELIVERY REFERENCE ID *</label>
          <input
              id="refId"
              type="text"
              v-model="delivery.referenceId"
              class="form-control"
              placeholder="ENTER DELIVERY REFERENCE ID"
              :class="{ 'is-invalid': submitted && $v.delivery.referenceId.$error }"
              style="text-transform: uppercase !important;"
          />
          <div
              v-if="submitted && !$v.delivery.referenceId.required"
              class="invalid-feedback"
          >DELIVERY REFERENCE ID IS REQUIRED</div>
        </div>
        <div class="form-group">
          <label for="name">DELIVERY DATE AND TIME *</label>
          <input
              id="name"
              type="datetime-local"
              v-model="delivery.dateAndTime"
              class="form-control"
              placeholder="ENTER DATE AND TIME"
              :class="{ 'is-invalid': submitted  && $v.delivery.dateAndTime.$error  }"
              style="text-transform: uppercase !important;"
          />
          <div
              v-if="submitted && !$v.delivery.dateAndTime.required"
              class="invalid-feedback"
          >DELIVERY DATE AND TIME IS REQUIRED</div>
        </div>
        <div class="form-group">
          <label>COLLECTED BY *</label>
          <div class="row">
            <div class="col-md-6">
              <input
                  type="text"
                  class="form-control"
                  v-model="delivery.collectedByName"
                  placeholder="ENTER NAME"
                  :class="{ 'is-invalid': submitted  && $v.delivery.collectedByName.$error  }"
                  style="text-transform: uppercase !important;"
              />
              <div
                  v-if="submitted && !$v.delivery.collectedByName.required"
                  class="invalid-feedback"
              >COLLECTED BY NAME IS REQUIRED</div>
            </div>
            <div class="col-md-6">
              <input
                  type="text"
                  class="form-control"
                  v-model="delivery.collectedByMobile"
                  placeholder="ENTER MOBILE NUMBER"
                  :class="{ 'is-invalid': submitted  && $v.delivery.collectedByMobile.$error  }"
                  style="text-transform: uppercase !important;"
              />
              <div v-if="submitted && $v.delivery.collectedByMobile.$error" class="invalid-feedback">
                <span v-if="!$v.delivery.collectedByMobile.required">COLLECTED BY MOBILE IS REQUIRED</span>
                <span v-if="!$v.delivery.collectedByMobile.numeric">COLLECTED BY MOBILE MUST BE IN NUMBER</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="name">DELIVERY RECEIPT *</label>
          <input
              class="ml-4"
              id="name"
              type="file"
              @change="deliveryRecipt"
              :class="{ 'is-invalid': submitted && $v.delivery.file.$error }"
              style="text-transform: uppercase !important;"
          />
          <div
              v-if="submitted && !$v.delivery.file.required"
              class="invalid-feedback"
          >DELIVERY RECEIPT FILE IS REQUIRED</div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">SAVE</button>
          <b-button class="ml-1" variant="danger" @click="hideModal">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->

    <!-- Modal Add -->
    <b-modal
        id="modal-1"
        v-model="showmodalPayment"
        title="ADD PAYMENT DETAILS"
        title-class="text-dark font-18"
        hide-footer
        @close="hideModalPayment"
    >

      <form @submit.prevent="handlePaymentSubmit">
        <div class="form-group">
          <label for="name">PAYMENT REFERENCE ID *</label>
          <input
              id="name"
              type="text"
              v-model="payment.referenceId"
              class="form-control"
              placeholder="ENTER PAYMENT REFERENCE ID"
              :class="{ 'is-invalid': submittedPayment  && $v.payment.referenceId.$error  }"
              style="text-transform: uppercase !important;"
          />
          <div
              v-if="submittedPayment && !$v.payment.referenceId.required"
              class="invalid-feedback"
          >REFERENCE ID IS REQUIRED</div>
        </div>
        <div class="form-group">
          <label for="name">AMOUNT *</label>
          <input
              id="name"
              v-model="payment.amount"
              type="number"
              class="form-control"
              placeholder="ENTER AMOUNT"
              :class="{ 'is-invalid': submittedPayment   && $v.payment.amount.$error  }"
              style="text-transform: uppercase !important;"
          />
          <div
              v-if="submittedPayment && !$v.payment.amount.required"
              class="invalid-feedback"
          >AMOUNT IS REQUIRED</div>
        </div>
        <div class="form-group">
          <label for="name">PAYMENT DATE AND TIME *</label>
          <input
              id="name"
              v-model="payment.paymentDateAndTime"
              type="datetime-local"
              class="form-control"
              placeholder="ENTER DATE AND TIME"
              :class="{ 'is-invalid': submittedPayment   && $v.payment.paymentDateAndTime.$error  }"
              style="text-transform: uppercase !important;"
          />
          <div
              v-if="submittedPayment && !$v.payment.paymentDateAndTime.required"
              class="invalid-feedback"
          >PAYMENT DATE AND TIME IS REQUIRED</div>
        </div>
        <div class="form-group">
          <label for="name">PAYMENT MODE *</label>
          <select class="form-control" v-model="payment.paymentMode" :class="{ 'is-invalid': submittedPayment   && $v.payment.paymentMode.$error  }" style="text-transform: uppercase !important;">
            <option value="">SELECT PAYMENT MODE</option>
            <option v-for="mode in paymentModeData" :value="mode.id" :key="mode.id">{{mode.mode}}</option>
          </select>
          <div
              v-if="submittedPayment && !$v.payment.paymentMode.required"
              class="invalid-feedback"
          >PAYMENT MODE IS REQUIRED</div>
        </div>
        <div class="form-group">
          <label for="name">PAYMENT RECEIPT *</label>
          <input
              class="ml-4"
              id="name"
              @change="paymentReceipt"
              type="file"
              :class="{ 'is-invalid': submittedPayment   && $v.payment.file.$error  }"
              style="text-transform: uppercase !important;"
          />
          <div
              v-if="submittedPayment && !$v.payment.file.required"
              class="invalid-feedback"
          >PAYMENT RECEIPT IS REQUIRED</div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">SAVE</button>
          <b-button class="ml-1" variant="danger" @click="hideModalPayment">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->


    <b-modal
            id="modal-1"
            v-model="showRejectModel"
            title="ADD REJECTED REASON"
            title-class="text-dark font-18"
            hide-footer
            @close="hideRejectModel">

      <form @submit.prevent="handleRejectedSubmit">
        <div class="form-group">
          <label for="name">REASON *</label>
          <textarea class="form-control" placeholder="ENTER REJECT REASON" v-model="reject.rejectedReason" :class="{ 'is-invalid': submittedReject   && $v.reject.rejectedReason.$error  }"></textarea>

          <div v-if="submittedReject && !$v.reject.rejectedReason.required" class="invalid-feedback">REASON IS REQUIRED</div>
        </div>

        <div class="text-right">
          <button type="submit" class="btn btn-success">SAVE</button>
          <b-button class="ml-1" variant="danger" @click="hideRejectModel">CANCEL</b-button>
        </div>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>